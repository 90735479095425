<template>
	<div id="myhome" class="myhome" ref="myhome" @click="onClose">
		<!-- 头部 nav导航 -->
		<header-nav></header-nav>
		<!-- 公司首页 -->
		<div ref="home1">
			<van-swipe :autoplay="3000" indicator-color="white" class="swipe-home">
				<div v-for="(item,index) in swipeList" :key="index" @click="goClick(item)">
					<van-swipe-item>
						<div class="swiper-image">
							<!-- <img :src="item.img" alt :style="is_vertical?'width: 100%;':''"/> -->
							<img :src="item.img" alt style="width: 100%;height: 100%;" :style="is_vertical?'margin-top: 10px;':''"/>
						</div>
					</van-swipe-item>
				</div>
			</van-swipe>
		</div>
		<!-- 关于我们-->
		<section class="uni-flex-column uni-flex-center" :style="is_vertical?'margin-top: 20px;':'margin-top: 30px;'">
			<div class="divider" ref="about">
				<div class="divider-title-bottom">ABOUT US</div>
				<div class="divider-title">关于我们</div>
				<div class="divider-bottom"></div>
			</div>
			<!-- 小屏 -->
			<div class="about-content-v" v-if="is_vertical">
				<div class="about-intro">{{aboutInfo.title}}</div>
				<div class="right-logo">
					<img :src="aboutInfo.thumbnail" style="width: 100%;height: 100%;"/>
				</div>
				<div class="cer-content">{{aboutInfo.summary}}</div>
				<div class="content-more" @click="goPage(1)">查看更多 >></div>
			</div>
			<!-- 大屏 -->
			<div class="about-content" v-else>
				<div class="about-intro">
					<div>{{aboutInfo.title}}</div>
					<div class="cer-content">{{aboutInfo.summary}}</div>
					<div class="content-more" @click="goPage(1,aboutInfo.id)">查看更多 >></div>
				</div>
				<div class="right-logo">
					<img :src="aboutInfo.thumbnail" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
		<!-- 工程案例 -->
		<section class="uni-flex-column uni-flex-center">
			<div class="divider" ref="case">
				<div class="divider-title-bottom">CASE</div>
				<div class="divider-title">工程案例</div>
				<div class="divider-bottom"></div>
			</div>
			<div class="case-content">
				<div class="casebox">
					<div class="case-box" v-for="(item,index) in caseList" :key="index" @click="goDetail(item)">
						<div class="case-logo">
							<img :src="item.thumbnail" style="width: 100%;height: 100%;"/>
						</div>
						<div class="case-label">{{item.title}}</div>
					</div>
				</div>
				<div class="content-more" @click="goPage(2,'')">查看更多 >></div>
			</div>
		</section>
		<!-- 中间图片 -->
		<!-- 小屏 -->
		<section class="middle" style="margin-top: 30px;"  v-if="is_vertical">
			<div class="middle-bg">
				<img src="/images/middle-app.png" class="middle-img" />
			</div>
		</section>
		<!-- 大屏 -->
		<section class="middle" style="margin-top: 30px;"  v-else>
			<div class="middle-bg">
				<img src="/images/middle-img.png" class="middle-img" />
			</div>
		</section>
		<div class="middle-content" v-if="!is_vertical">
			<div class="middle-box" v-for="(item,index) in productList" :key="index" v-if="index%3==0">
				<div class="product-logo" v-if="index!=0">
					<img :src="productList[index+2].url" style="width: 100%;height: 100%;"/>
				</div>
				<div class="uni-flex-column uni-flex-center">
					<div class="product-label">
						<div class="num">{{productList[index].num}}</div>
						<div class="line-div"></div>
						<div class="">
							<div class="title">{{productList[index].title}}</div>
							<div class="subTitle">{{productList[index].subTitle}}</div>
						</div>
					</div>
					<div class="product-label">
						<div class="num">{{productList[index+1].num}}</div>
						<div class="line-div"></div>
						<div class="">
							<div class="title">{{productList[index+1].title}}</div>
							<div class="subTitle">{{productList[index+1].subTitle}}</div>
						</div>
					</div>
				</div>
				<div class="product-logo" v-if="index==0">
					<img :src="productList[index+2].url" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</div>
		<!-- 合作流程 -->
		<section class="process-box uni-flex-column uni-flex-center">
			<div class="divider" ref="process">
				<div class="divider-title-bottom">PROCESS</div>
				<div class="divider-title">合作流程</div>
				<div class="divider-bottom"></div>
			</div>
			<div class="plan-content">
				<div class="plan-logo">
					<img src="/images/plan.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
		<!-- 新闻资讯 -->
		<section class="uni-flex-column uni-flex-center" style="margin-top: 30px;">
			<div class="divider" ref="news">
				<div class="divider-title-bottom">NEWS</div>
				<div class="divider-title">新闻资讯</div>
				<div class="divider-bottom"></div>
			</div>
			<div class="new-content">
				<div class="new-box" v-if="infoList.length>0">
					<div class="new-first" @click="goDetail(infoList[0])">
						<div class="new-logo">
							<img :src="infoList[0].thumbnail" style="width: 100%;height: 100%;"/>
						</div>
						<div class="title">{{infoList[0].title}}</div>
						<div class="subTitle">{{infoList[0].summary}}</div>
					</div>
					<div class="uni-flex-column">
						<div class="new-list" v-for="(item,index) in infoList" :key="index" v-if="index>0" @click="goDetail(item)">
							<div class="uni-flex uni-flex-center">
								<div class="circle"></div>
								<div class="title">{{item.title}}</div>
							</div>
							<div class="time">{{item.createTime}}</div>
						</div>
					</div>
				</div>
				<div class="new-more" @click="goPage(3,'')">查看更多 >></div>
			</div>
		</section>
		<!-- 底部链接 -->
		<!-- 友情链接 -->
		<section class="friend uni-flex-column uni-flex-center" style="margin-top: 30px;">
			<div class="friend-content">
				<div class="friend-text">
					<span>友情链接：</span>
					<a href="https://shop117115684.taobao.com/?spm=a2142.8996326.sellercard.15.146f2be3VrqbMR">千雪制冷淘宝店</a>
				</div>
			</div>
		</section>
		<!-- 联系我们 -->
		<!-- 小屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-if="is_vertical">
			<div class="foot-content">
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="foot-scan">微信扫一扫</div>
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
			</div>
		</section>
		<!-- 大屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-else>
			<div class="foot-content">
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
		
		<!-- 小屏 右下角联系方式 -->
		<div class="right-bottom" v-if="is_vertical && showPhone" @click="goPhone">
			<img src="/images/mobile.png" alt style="width: 100%;"></img>
		</div>
		<!-- 小屏 左上角联系方式 -->
		<div class="left-top" v-if="is_vertical && isshow">
			<img src="/images/app-msk.png" alt ></img>
		</div>
	</div>
</template>

<script>
	import api from '@/utils/api.js';
	import headerNav from '@/components/header.vue';
	export default {
		name: 'myhome', // 首页
		components: {
			headerNav,
		},
		data() {
			return {
				is_vertical: false,  //false 小屏  true 大屏
				isshow: false,
				showPhone: true,
				win_width: document.body.clientWidth,
				body_width: '',
				body_height: '',
				aboutShow: 1,
				processShow: 1,
				caseShow: 1,
				navShow: false,
				SelectShow: false,
				SelectShowLang: false,
				reLangs: true,
				//关于我们
				aboutInfo: {   
					id: '',
					title: '以创新的技术，产品与设计重塑人与环境，使生活更美',
					thumbnail: '/images/about-bg3.png',
					summary: '宁德市千雪制冷设备有限公司是一家集冷库、冷柜、风幕柜、保鲜库、冷冻库、各种制冰机、工业冷水机、制冷半封闭压缩机、中央空调等各种制冷设备设计、维修、安装的公司，拥有十几年的制冷经验，本着诚实、诚信、服务至上的原则服务了多家大、中、小型公司以及个体客户。 可承建成不同大小规模的冷库。我中心工程部按冷库的面积、温度及不同的使用需要给予全理化配置设计安装。以最先进的设计方案为客户降低成本投资，多品种，多规格的库板也可根据您的需要定制特殊的异型冷库，确保库的精度。外型美观为用户充分利用现有场地和建筑空间提供方便并可满足不同温度的需求。 在自身发展的过程中，本公司始终本着以人为本，以科技促发展的经营理念。'
				},
				//新闻资讯
				infoList: [],
				//工程案例
				caseList: [],
				// 轮播图
				swipeList: [],
				//中间产品列表
				productList: [
					{
						num: '01',
						title: '企业宗旨',
						subTitle: '百无失一、精进不休'
					},
					{
						num: '02',
						title: '企业精神',
						subTitle: '严于律己、一丝不苟、精益求精'
					},
					{
						url: '/images/case-1.png'
					},
					{
						num: '03',
						title: '质量保证',
						subTitle: '专业的施工队伍成就可靠品质'
					},
					{
						num: '04',
						title: '形象打造',
						subTitle: '诚实守信、遵纪守法、创新发展'
					},
					{
						url: '/images/case-1.png'
					},
				],
				
			}
		},

		computed: {
			
		},
		created() {
			this.api_bannerList();
			this.api_bannerList2();
			this.api_menuList();
		},
		mounted() {
			console.log(this.$route.query.name);
			if (this.$route.query.name) {
				this.scrollIntoView(this.$route.query.name);
				this.navShow = false;
			}
			this.$refs.myhome.addEventListener('scroll', this.handleScroll);
			
			/* 获取页面高宽 */
			// this.body_width = document.getElementById('myhome').offsetWidth;
			// this.body_height = document.getElementById('myhome').clientHeight;
			const that = this;
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.win_width = window.screenWidth;
					// this.$store.commit('set_win_width', that.win_width);
					// this.$cookieStore.setCookie('win_width', that.win_width, 1000 * 60 * 60 * 6);
				})();
			};
			console.log(this.win_width);
			if (this.win_width < 768) {
				//小屏
				this.is_vertical = true;
			} else if (this.win_width >= 768) {
				//大屏
				this.is_vertical = false;
			}
			//小屏下判断是否是微信浏览器
			if(this.is_vertical) {
				var ua = navigator.userAgent.toLowerCase();
				var Weixin = ua.indexOf('micromessenger') != -1;
				if (Weixin) {
					return this.isshow = true;
				} else {
					return this.isshow = false;
				}
			}
		},

		watch: {
			$route() {
				console.log(this.$route.query.name);
				if (this.$route.query.name) {
					this.scrollIntoView(this.$route.query.name);
					this.navShow = false;
				}
				this.$refs.myhome.addEventListener('scroll', this.handleScroll);
			},
			
		},
		methods: {
			api_bannerList() {
				api.bannerList({position:0}).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.swipeList = res.data;
					}
				})
			},
			api_bannerList2() {
				api.bannerList({position:1}).then(res=>{
					console.log(res);
					if(res.code == 0){
						if(res.data.length>=2) {
							this.productList[2].url = res.data[0].img
							this.productList[5].url = res.data[1].img
						}
						else if(res.data.length==1){
							this.productList[2].url = res.data[0].img
							this.productList[5].url = res.data[0].img
						}
					}
				})
			},
			api_menuList() {
				api.menuList().then(res=>{
					console.log(res);
					if(res.code == 0){
						res.data.forEach(item => {
							if(item.title == "关于我们") {
								this.api_aboutUS(item.id);
							}
							else if(item.title == "工程案例") {
								this.api_caseList(item.id);
							}
							else if(item.title == "新闻资讯") {
								this.api_newsList(item.id);
							}
						});
					}
				})
			},
			api_aboutUS(id) {
				let param = {
					categoryId: id,
					current: 1,
					size: 1,
				};
				api.newsList(id,param).then(res=>{
					console.log(res);
					if(res.code == 0){
						if(res.data.records.length>0) {
							this.aboutInfo = res.data.records[0];
						}
					}
				})
			},
			api_newsList(id) {
				let param = {
					categoryId: id,
					current: 1,
					size: 5,
				};
				api.newsList(id,param).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.infoList = res.data.records;
					}
				})
			},
			api_caseList(id) {
				let param = {
					categoryId: id,
					current: 1,
					size: 6,
				};
				api.newsList(id,param).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.caseList = res.data.records;
					}
				})
				
			},
			handleScroll() { // 实现当滚动到指定位置，触发动画
				//let scrollTop6 =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
				// this.gdjz('earth', 'earth animated bounceInDown', 50)
				let _this = this;
				let refArray = [{
					ref: 'about',
					show: 'aboutShow'
				},{
					ref: 'case',
					show: 'caseShow'
				},{
					ref: 'process',
					show: 'processShow'
				}, ]
				refArray.forEach((r) => {
					_this.gdjz(r.ref, 20, () => {
						if (_this[r.show] == 1) {
							_this[r.show] = 0;
							_this.$nextTick(function() {
								_this[r.show] = 2;
							})
							console.log('滑到：' + r.show);
						}
					})
				})
			},
			gdjz(div, offset, callback) {
				let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
				if (dom) {
					var a, b, c, d;
					d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
					a = eval(d + offset)
					b = this.$refs.myhome.pageYOffset || this.$refs.myhome.scrollTop //  获取窗口滚动条高度
					c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
					if (b + c > a) {
						callback();
					}
				}
			},
			/* 跳转到指定res */
			scrollIntoView(name) {
				console.log(name);
				this.$refs[name].scrollIntoView({
					behavior: "smooth",  // 平滑过渡
					block:    "center"  // 上边框与视窗顶部平齐。默认值
				});
				this.navShow = false;
			},
			openNav() {
				this.navShow = true;
			},
			canse() {
				this.isshow = false;
			},
			onClose() {
				this.SelectShow = false;
				this.SelectShowLang = false;
			},
			//跳转更多页面
			goPage(val,id) {
				switch (val){
					case 1: //关于我们
						if(id!='') {
							this.$router.push({
								name: 'about',
								query:{
									id:id
								}
							})
						}
						else {
							this.$router.push({
								name: 'about',
							})
						}
						
						break;
					case 2: //工程案例
						this.$router.push({
							name: 'case',
						})
						break;
					case 3: //新闻资讯
						this.$router.push({
							name: 'news',
						})
						break;
					default:
						break;
				}
				
			},
			_subTime(val) {
				console.log(val);
				return val.substr(0,10)
			},
			goDetail(item) {
				this.$router.push({
					name: 'newsDetail',
					query:{
						id:item.id
					}
				})
			},
			goClick(item) {
				
			},
			goPhone() {
				api.settingInfo({key: 'cfg_company_phone'}).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.showPhone = false;
						window.location.href = 'tel:'+res.data
					}
				})
			},

		}
	}
</script>

<style scoped="" lang="less">
	.yx-h5 {
		font-size: 14px;
	}

	.myhome {
		height: 100%;
		overflow: auto;
		font-size: 20px;
		color: #bdbdbd;
		background-color: #FFFFFF;
	}

	.swipe-home {
		width: 100%;
		height: 600px;
		// display:flex;
		// align-items:center;
		// justify-content:center;
		.swiper-image {
			width: 100%;
			height: 600px;
		}
	}
		
	/* 标题 */
	.divider {
		width: 80%;
		max-width: 1200px;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	.divider-title-bottom {
		font-size: 36px;
		font-weight: bold;
		color: #999999;
		line-height: 20px;
		opacity: 0.2;
	}

	.divider-title {
		font-size: 30px;
		font-weight:bold;
		letter-spacing: 5px;
		color: #489BD6;
		font-family:Microsoft YaHei;
		margin: -15px 0 15px;

		&:before {
			content: "";
			height: 5px;
			width: 30%;
			background-color: #bdbdbd;
		}
	}
	
	.divider-bottom {
		width: 100%;
		height: 1px;
		background: #DEDEDE;
	}

	.about-content {
		width:1200px;
		height:450px;
		margin: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px 0;
		
		.about-intro {
			text-align: left;
			font-weight: bold;
			color: #181818;
			width: 400px;
			font-size: 16px;
			
			.cer-content {
				margin-top: 30px;
				font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif;
				line-height: 25px;
				font-size: 14px;
				font-weight: normal;
				
				display: -webkit-box;
				word-wrap:break-word;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
			}
			
			.content-more {
				margin-top: 50px;
				margin-bottom: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 140px;
				height: 40px;
				border: 1px solid #136CC0;
				border-radius: 20px;
				font-size: 14px;
				font-weight: normal;
				color: #136CC0;
			}
		}
		
		.right-logo {
			width: 736px;
			height: 375px;
		}
	}

	.case-content {
		width: 1200px;
		margin-bottom: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		.casebox {
			display: flex;
			flex-wrap: wrap; 
			justify-content: space-between;
			width: 100%;
			
			.case-box {
				padding: 30px 0;
				
				.case-logo {
					width: 300px;
					height: 260px;
				}
				
				.case-label {
					margin-top: 50px;
					width: 300px;
					height: 60px;
					border: 2px solid #E9E9E9;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #646464;
					font-size: 18px;
				}
			}
		}
	
		.content-more {
			margin-top: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 140px;
			height: 40px;
			border: 1px solid #136CC0;
			border-radius: 20px;
			font-size: 14px;
			font-weight: normal;
			color: #136CC0;
		}
	}
		
	.process-box {
		position: relative;
		background: url(../../../public/images/process-bg.png);
		background-size:100% 100%;
		width: 100%;
		height: 835px;
		z-index: 0;
		
		.plan-content {
			margin: 150px auto 50px;
			
			.plan-logo {
				width:1000px;
				height:409px;
			}
		}
	}
	
	.bottom-content {
		position: relative;
		width:1200px;
		height:800px;
		margin-bottom: 50px;
		
		.bottom-text {
			font-size:72px;
			font-family:Microsoft YaHei;
			font-weight:bold;
			color:rgba(72,155,214,1);
			margin-top: 30px;
		}
		
		.bottom-text1 {
			font-size:36px;
			font-family:Microsoft YaHei;
			font-weight:400;
			color:rgba(0,0,0,1);
			line-height:48px;
			margin: 30px 0;
		}
		
		.bottombox {
			display: flex;
			flex-direction: column;
			margin-top: 50px;
			
			.bottomlist {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
				margin: 15px 0;
				
				.bottomicon {
					height: 50px;
					width: 160px;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					margin: 0 20px;
				}
			}
		}
		
	}

	.about {
		max-width: 750px;
		font-size: 16px;
		margin: 20px;
		padding: 20px;
		border-radius: 5px;
		color: #b5a36f;
		text-align: left;
	}
	
	.middle {
		.middle-bg {
			width: 100%;
			height: 200px;
			.middle-img {
				height: 100%;
				width: 100%;
			}
		}
	}
	
	.middle-content {
		width: 1200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 30px auto 50px;
		
		.middle-box {
			display: flex;
			justify-content: space-between;
			width: 100%;
			.product-label {
				text-align: left;
				display: flex;
				width: 510px;
				padding: 20px 50px;
				color: #136CC0;
				
				.num {
					font-size: 30px;
					font-weight: bold;
					margin-right: 20px;
				}
				.title {
					margin-top: 20px;
					font-size: 20px;
				}
				.subTitle {
					color: #000000;
					font-size: 16px;
				}
				.line-div{
					width: 50px;
					border-bottom: 2px solid #136CC0;
					margin-left: -10%;
					margin-bottom: 30px;
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					filter: progid:DXImageTransform.Microsoft.BasicImage(Rotation=0.45);
				}
			}
			
			.product-logo {
				width: 590px;
				height: 317px;
			}
		}
	}

	.new-content {
		width: 1200px;
		margin-top: 50px;
		margin-bottom: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.new-box {
			display: flex;
			flex-wrap: wrap; 
			justify-content: space-between;
			width: 100%;
			.new-first {
				width: 500px;
				.new-logo {
					width: 500px;
					height: 185px;
				}
				.title {
					font-size: 18px;
					font-family: 59;
					font-weight: 400;
					color: #484848;
					line-height: 20px;
					text-align: left;
					margin-top: 10px;
					
					display: -webkit-box;
					word-wrap:break-word;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
				.subTitle {
					color: #999999;
					margin-top: 10px;
					text-align: left;
					font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif;
					line-height: 20px;
					font-size: 14px;
					font-weight: normal;
					
					display: -webkit-box;
					word-wrap:break-word;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
			.new-list {
				width: 600px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 10px;
				border-bottom: 1px solid #DDDDDD;
				font-size: 16px;
				.circle {
					width: 10px;
					height: 10px;
					border: 1px solid #C0C0C0;
					border-radius: 50%;
					margin-right: 10px;
				}
				.title {
					color: #646464;
					width: 100%;
					text-align: left;
					display: -webkit-box;
					word-wrap:break-word;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
				.time {
					width: 200px;
					color: #B9B9B9;
					text-align: right;
				}
			}
		}
	
		.new-more {
			margin-top: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 140px;
			height: 40px;
			border: 1px solid #136CC0;
			border-radius: 20px;
			font-size: 14px;
			font-weight: normal;
			color: #136CC0;
		}
	}
	
	.friend {
		margin-top: 30px;
		height: 50px;
		background-color: #F5F5F5;
		
		.friend-content {
			display: flex;
			flex-direction: wrap;
			width: 1200px;
			
			.friend-text {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 24px;
			}
		}
	}
	
	.foot {
		height: 200px;
		background-color: #0E82D9;
		
		.foot-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			
			.foot-text {
				display: flex;
				flex-direction: column;
				text-align: left;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				.span {
				}
			}
			
			.foot-logo {
				width: 142px;
				height: 142px;
			}
		}
	}
	
	.right-bottom {
		position: fixed;
		bottom: 200px;
		right: 50px;
		width: 150px;
		height: 150px;
		// background: red;
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
		z-index: 10000;
		
		.close-btn {
			margin-top: -30px;
			text-align: right;
		}
	}
	
	/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		
		// .myhome {
		// 	overflow-y: auto;
		// 	overflow-x: hidden;
		// }
		.swipe-home {
			width: 100%;
			height: 180px;
			
			.swiper-image {
				width: 100%;
				height: 180px;
			}
		}
		
		/* 标题 */
		.divider-title-bottom {
			font-size: 20px !important;
			color: #111111 !important;
			opacity: 1 !important;
		}
		
		.divider-title {
			color: #111111;
			font-size: 16px !important;
			margin: 15px 0 15px;
			text-align: center;
		}
		
		.divider-bottom {
			width: 20px;
			height: 3px;
			background: #136CC0;
		}
		
		.about-content-v {
			width:100%;
			margin: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.about-intro {
				margin: 0 20px 20px;
				text-align: left;
				font-weight: bold;
				color: #181818;
				font-size: 18px;
			}
			
			.right-logo {
				width:90%;
				height: 150px;
			}
			
			.cer-content {
				text-align: left;
				margin: 20px;
				font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif;
				line-height: 25px;
				font-size: 14px;
				font-weight: normal;
				color: #181818;
				display: -webkit-box;
				word-wrap:break-word;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
			}
			
			.content-more {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100px;
				height: 40px;
				background: #F2F2F2;
				font-size: 14px;
				font-weight: normal;
				color: #1166BB;
			}
		}
		
		.case-content {
			width:100% !important;
			margin: 30px !important;
			.casebox {
				display: flex;
				flex-wrap: wrap; 
				// justify-content: space-around;
				// padding: 0 15px;
				width: 95%;
				.case-box {
					padding: 10px 5px;
					.case-logo {
						width: 150px;
						height: 100px;
					}
					
					.case-label {
						margin-top: 20px;
						width: 150px;
						height: 40px;
						font-size: 14px;
					}
				}
			}
		
			.content-more {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100px;
				height: 40px;
				background: #F2F2F2;
				border: 0px solid #F2F2F2;
				border-radius: 0px;
				font-size: 14px;
				font-weight: normal;
				color: #1166BB;
				margin-top: 20px;
			}
		}
				
		.middle {
			.middle-bg {
				width: 100%;
				height: 450px;
				.middle-img {
					height: 100%;
					width: 100%;
				}
			}
		}
		
		.process-box {
			height: 450px;
			
			.plan-content {
				margin: 100px auto 30px;
				display: flex;
				justify-content: center;
				.plan-logo {
					width:95%;
					height:150px;
				}
			}
		}
		
		.new-content {
			width: 100%;
			margin-top: 20px;
			margin-bottom: 50px;
			.new-box {
				width: 90%;
				.new-first {
					width: 100%;
					.new-logo {
						width: 100%;
						height: 150px;
					}
					.title {
						font-size: 16px;
						margin-top: 10px;
					}
					.subTitle {
						font-size: 12px;
					}
				}
				.new-list {
					width: 100%;
					padding: 10px 0;
					font-size: 12px;
					.circle {
						width: 5px;
						height: 5px;
						margin-right: 5px;
					}
					.title {
						width: 180px;
					}
					
					.time {
						width: 150px;
					}
				}
			}
			.new-more {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100px;
				height: 40px;
				background: #F2F2F2;
				border: 0px solid #F2F2F2;
				border-radius: 0px;
				font-size: 14px;
				font-weight: normal;
				color: #1166BB;
				margin-top: 20px;
			}
		}
			
		.bottom-content {
			width:340px;
			height:400px;
			margin-bottom: 50px;
			
			.bottom-text {
				font-size:20px;
				margin-top: 30px;
			}
			
			.bottom-text1 {
				font-size:16px;
				margin: 10px 0;
			}
			
			.bottombox {
				margin-top: 20px;
				.bottomlist {
					.bottomicon {
						height: 18px;
						width: 45px;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						margin: 0 5px;
					}
				}
			}
			
		}
		
		.friend {
			margin-top: 30px;
			height: 30px;
			background-color: #F5F5F5;
			
			.friend-content {
				display: flex;
				flex-direction: wrap;
				width: 100%;
				margin-left: 10px;
				.friend-text {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
				}
			}
		}
		
		.foot {
			// margin-top: 30px;
			height: 370px;
			background-color: #0E82D9;
			
			.foot-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				.foot-scan {
					padding: 20px 0;
					text-align: center;
					font-size: 12px;
					color: #FFFFFF;
				}
				.foot-text {
					margin-top: 20px;
					text-align: center;
					font-size: 12px;
				}
				
				.foot-logo {
					width: 120px;
					height: 120px;
				}
			}
		}

		.right-bottom {
			position: fixed;
			bottom: 100px;
			right: 20px;
			width: 80px;
			height: 80px;
			// background: red;
			display: flex;
			flex-direction: column;
			justify-content: start;
			align-items: center;
			z-index: 10000;
		}
		
		.left-top {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.7);
			display: flex;
			flex-direction: column;
			justify-content: start;
			align-items: center;
			z-index: 10000;
		}
		
		.Novel {
			margin-top: 20px;
		}

		.header .content .nav {
			display: none !important;
		}

		.content {
			justify-content: space-between !important;
			padding: 0 20px;

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}

			.langs {
				display: none;
			}
		}
	}
</style>
